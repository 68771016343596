import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo'

export default function GeschwisterGerson(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >   
        <MySeo title="Das Schicksal der jüdischen Geschwister Gerson" description="Dokumentation vom K.O.M" />
        <Box  mt="60px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        
        <SimpleGrid  gap={4} columns={[1,1,2]}>
       
        <Box>
            <Heading variant="utitel" py="8">Chablis-Straße 12
            <br/>
            Geschwister Gerson – Geschwister Kahn
            </Heading>
            
            <Text variant="solid">
            In der Simmerner Straße 12 (früher 8a) wohnte seit 1904 der Viehhändler und Metzger Theodor Gerson (*1874) mit seiner Frau Bertha, geb. Kahn (*1875 in Hillesheim bei Mainz). Sein Vater Josef, geboren in Perscheid, hatte das Haus gekauft und lebte bis zu seinem Tod 1905 hier. Theo ist ein Cousin von Gustav Gerson. Bertha und Theo Gerson hatten einen Sohn: Leo (*1905) kam 1928 mit 23 Jahren bei einem Autounfall ums Leben. Theo Gerson starb 1933. Sie sind auf dem Oberweseler Friedhof bestattet. Ab 1930 kamen bei Gersons mehrere meist betagte Verwandte unter, die aus ihren Häusern vertrieben worden waren, zuletzt auch Rosalie und Else Trum und Gustav Gerson, vertrieben aus der Kirchgasse. Alle wurden ermordet.
            </Text>
            <Heading variant="utitel">Im Jahre 1930</Heading>
            <Text variant="solid">zieht Theo Gersons Schwester Bertha Salomon, geboren am 13.03.1870 in Perscheid, nach dem Tod ihres Mannes Isidor Salomon aus Bad Neuenahr nach Oberwesel.</Text>
            <Heading variant="utitel">16. Dezember 1938</Heading>
            <Text variant="solid">Bertha Gersons Bruder, der Kaufmann Albert Kahn, geboren am 09.01.1873 in Hillesheim, und seine Frau Bertha, geb. Löb, geboren am 01.02.1883 in Reichelsheim im Odenwald, ziehen nach Oberwesel, nachdem sie ihr Haus und Geschäft in Hillesheim aufgeben mussten. Albert Kahn war der letzte Gemeindevorsteher von Hillesheim. Ihre Söhne Leo und Ludwig konnten in die USA fliehen, Alfred nach Palästina.</Text>
            <Heading variant="utitel">11. August 1939</Heading>
            <Text variant="solid">Sara Kahn, geboren am 11.10.1873 in Hillesheim, die ledige Schwester Bertha Gersons und Albert Kahns, zieht von Weinheim an der Bergstraße nach Oberwesel.</Text>
            <Heading variant="utitel">November 1939</Heading>
            <Text variant="solid">Gustav »Israel« Gerson erhält ein Schreiben des Bürgermeisters und NSDAP-Ortsgruppenleiters Fritz Bosche: »Auf Grund des Ministerialerlasses vom 4.5.1939 zur Durchführung des Gesetzes über Mietverhältnisse mit Juden werden Sie hiermit aufgefordert, sich bis zum 15. ds. Mts. eine Wohnung in einem jüdischen Anwesen hier zu suchen. Nach diesem Zeitpunkt werden entsprechende Massnahmen in die Wege geleitet.« Gustav Gerson zieht vorübergehend zu seinem Bruder Leopold nach Duisburg, findet aber Anfang Oktober 1939 Obdach bei der Witwe seines Cousins Theo, Bertha Gerson, geb. Kahn. Dort sind auch Else Trum und ihre Mutter Rosalie Trum untergekommen.</Text>
            <Heading variant="utitel">17. Januar 1940</Heading>
            <Text variant="solid">Rosalie Trum flieht ohne ihre Tochter Else, die keine Ausreisegenehmigung erhalten hat, nach Argentinien und weiter in die USA</Text>
            <Heading variant="utitel">27. Januar 1941</Heading>
            <Text variant="solid">Else Trum zieht nach Düsseldorf, wo sie aber nicht dauerhaft bleiben kann. Sie kommt am 18.02.1941 zurück.</Text>
            <Heading variant="utitel">2. April 1942</Heading>
            <Text variant="solid">Die fünf betagten Bewohner des Hauses Simmerner Straße 8a werden gezwungen, in das zum »Judenhaus« erklärte Haus von Adolf Seligmann zu ziehen, Heumarkt 11 (früher 29).</Text>
            <Heading variant="utitel">2. April 1942 </Heading>
            <Text variant="solid">Else Trum wird zusammen mit den anderen deportierten Juden nach Salzig in das Hotel Schwan, Bopparder Straße 13, gebracht. Sie wird am 30.04. 1942 von Koblenz-Lützel ins Ghetto Krasniczyn transportiert, Schicksal unbekannt.
            </Text>
            <Heading variant="utitel">27. Juli 1942</Heading>
            <Text variant="solid">Zusammen mit den anderen verbliebenen Juden werden Albert Kahn und seine Frau Bertha, geb. Gerson, die Witwe Bertha Gerson, geb. Kahn, ihre Schwester Sara Kahn und die Witwe Bertha Salomon, geb. Gerson, zum Bahnhof getrieben und zum Güterbahnhof Koblenz-Lützel verbracht. Dort werden sie in den Zug nach Theresienstadt in der besetzten Tschechoslowakei verladen. Albert Kahn wird nach Auschwitz verschleppt und dort ermordet. Seine Frau Bertha Kahn stirbt in Theresienstadt am 29.09.1942, Bertha Salomon am 19.01.1944, die anderen alten Leute werden später zum 31.12.1942 für tot erklärt.</Text>
            
            </Box>
            <Box>
                <Box p="1">
                    <StaticImage src="../images/solo/berta-gerson.jpg"/>
                    <Text variant="Bild">Berta Gerson geb. Kahn </Text>
                </Box>
               
                <Box p="1">
                    <StaticImage src="../images/solo/berta-salomon.jpg" alt="Berta Salomon geb. Gerson" />
                    <Text variant="Bild">Berta Salomon geb. Gerson</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/leogerson-grab.jpg" alt="Grabstein von Leo Gerson" />
                    <Text variant="Bild">Grabstein von Leo Gerson</Text>
                </Box>
            </Box>
            </SimpleGrid>
            </motion.div>
    )
}